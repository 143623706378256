
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
                <v-layout row wrap px-4 mb-4>
                    <v-flex xs9>
                        <v-breadcrumbs class="px-0" :items="$store.getters.language.data.categories.list_path">
                            <template v-slot:item="{item}">
                                <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                    {{ item.title }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-flex>
                    <v-flex xs3></v-flex>
                </v-layout>
                <form @submit.prevent="addCategories" autocomplete="off">
                    <v-layout row wrap>
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-text-field v-model="categories.category_name" type="text" :label="$store.getters.language.data.categories.category_name" dense
                                class="mx-1"  filled outlined required>
                            </v-text-field>
                        </v-flex>
                    
                        <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-select class="mx-1" clearable  :items="[{text: $store.getters.language.data.yes, value: 'true'}, {text: $store.getters.language.data.no, value: 'false'}]" v-model="categories.available_to_shop" dense  filled outlined item-text="text"
                                item-value="value" :return-object="false" :label="$store.getters.language.data.categories.available_to_shop">
                            </v-select>
                        </v-flex> -->
<!--                 
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-select v-model="categories.category_parent" :label="$store.getters.language.data.categories.category_parent" dense
                                class="mx-1"  filled outlined :items="rows" item-text="category_name" item-value="category_id" clearable>
                            </v-select>
                        </v-flex> -->

                        <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-autocomplete v-model="categories.file_attachment_id" :label="$store.getters.language.data.categories.file_attachment" dense
                                class="mx-1"  filled outlined :items="file_attachments" item-text="file_attachment_name" item-value="file_attachment_id">
                                <template v-slot:item="{item}">
                                    <v-img :src="$image_url + 'file_attachments/serve_file/' + item.file_attachment_path" contain max-width="70" class="mb-2"></v-img>
                                    <span class="ms-1"> {{ item.file_attachment_name }} </span>
                                </template>
                            </v-autocomplete>
                        </v-flex> -->

                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.categories.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-text>
                <v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="filtered_rows" class="elevation-0"
                    item-key="category_id">
                    <template v-slot:[`item.category_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/categories-list/'+item.category_id"  color="teal" class="mx-1">
                            <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="error" icon class="mx-1" @click="selectCategories(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                        </div>
                    </template>
                    
                    <template v-slot:[`item.category_status`]="{ item }">
                        <div>
                            <v-chip color="success" small v-if="item.category_status == 'enable'"> {{ $store.getters.language.data.enabled }} </v-chip>
                            <v-chip color="error" small v-else> {{ $store.getters.language.data.disabled }} </v-chip>
                        </div>
                    </template>
                    <template v-slot:[`item.available_to_shop`]="{ item }">
                        <div>
                            <v-chip color="success" small v-if="item.available_to_shop == 'true'"> {{ $store.getters.language.data.yes }} </v-chip>
                            <v-chip color="error" small v-else> {{ $store.getters.language.data.no }} </v-chip>
                        </div>
                    </template>

                    <template v-slot:[`item.file_attachment_path`]="{ item }">
                        <v-img :src="$image_url + 'file_attachments/serve_file/' + item.file_attachment_path" contain max-width="100"></v-img>
                    </template>

                </v-data-table>
            </v-card-text>
            
            <v-card-actions v-if="selected_rows.length > 0">
                <v-btn color="error" @click="deleteCategoriesList">{{$store.getters.language.data.categories.delete_list_btn}}</v-btn>
            </v-card-actions>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>
<v-dialog v-model="delete_dialog" persistent max-width="400">
    <v-card>
        <v-card-title>
            {{$store.getters.language.data.categories.delete_question}}
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="elevation-0" @click="delete_dialog = false">
                {{$store.getters.language.data.categories.cancel_btn}}
            </v-btn>
            <v-btn color="error" class="elevation-0" @click="deleteCategories(selected_categories.category_id)">
                {{$store.getters.language.data.categories.yes_btn}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/categories.request.js'
    export default {
        data() {
            return {
                categories: {},
                search: '',
                loading : false,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                selected_categories : {},
                delete_dialog: false,
                headers: [
                    { 
                        text: this.$store.getters.language.data.categories.category_name,
                        align: 'start',
                        sortable: true,
                        value: 'category_name',
                    },
                    // { 
                    //     text: this.$store.getters.language.data.categories.category_status,
                    //     align: 'start',
                    //     sortable: true,
                    //     value: 'category_status',
                    // },
                    // { 
                    //     text: this.$store.getters.language.data.categories.available_to_shop,
                    //     align: 'start',
                    //     sortable: true,
                    //     value: 'available_to_shop',
                    // },
                    // { 
                    //     text: this.$store.getters.language.data.categories.category_parent,
                    //     align: 'start',
                    //     sortable: true,
                    //     value: 'category_parent_name',
                    // },   
                    // { 
                    //     text: this.$store.getters.language.data.categories.file_attachment,
                    //     align: 'start',
                    //     sortable: true,
                    //     value: 'file_attachment_path',
                    // },   
                    {
                        text: this.$store.getters.language.data.actions,
                        align: 'start',
                        sortable: true,
                        value: 'category_id',
                    }
                ],
            }
        },
        computed: {
            rows() {
                return this.$store.getters.categories_list
            },
            filtered_rows() {
                return this.rows.filter(obj => {
                    return this.categories.category_parent ? obj.category_parent == this.categories.category_parent : true
                })
            },
            file_attachments(){
                return this.$store.getters.file_attachments_list
            },
            buttonDisable() {
                return !this.categories.category_name ||
                    !this.categories.available_to_shop
            }
        },
        mounted(){

        },
        methods: {
            addCategories() {
                this.loading_btn = true
                this.categories.store_id = this.$store.getters.store.store_id;
                this.categories.category_status = 'enable';
                requests.createCategories(this.categories).then(r => {
                    if (r.status == 200) {
                        this.categories = {}
                        this.$store.state.categories_list.push(r.data.new_data)
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.add_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteCategories(category_id) {
                requests.deleteCategories(category_id).then(r => {
                    this.delete_dialog = false
                    if (r.status == 200) {
                        this.$store.state.categories_list = this.$store.state.categories_list.filter(f =>{
                            return f.category_id != category_id
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            deleteCategoriesList() {
                let ids = this.selected_rows.map(m => m.category_id)
                requests.deleteCategoriesList(ids).then(r => {
                    if (r.status == 200) {
                        this.$store.state.categories_list = this.$store.state.categories_list.filter(f =>{  
                            return ids.indexOf(f.category_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                        this.selected_rows = [];
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            selectCategories(i) {
                this.selected_categories = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    